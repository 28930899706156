import { ListItem } from '@local/web-design-system-2/dist/components/GenericListing/ListItem';
import type { FieldDefinition } from '@local/web-design-system-2/dist/components/GenericListing/types';
import TableSkeleton from '@local/web-design-system-2/dist/components/TableSkeleton/TableSkeleton';
import FileRestoreIcon from '@local/web-design-system-2/dist/icons/FileRestore';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import type { ListFile } from 'src/apiClients/GENERATED_fileClientEndpoints';
import { useRestoreItemHandler } from 'src/hooks/useRestoreItemHandler';
import { isItemChecked, toggleChecked } from 'src/store/features/multiSelectSlice';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { RESTORE_ACTION_TOOLTIP } from 'src/strings';
import { ItemType } from 'src/types/ItemTypes';

const RestoreAction = ({ onRestore, canRestore }: RecycleActionProps) => (
    <Grid
        gap={2}
        container
        alignItems="center"
        justifyContent="center"
        item
        xs
        automation-id="file-row-restore-action"
    >
        <Tooltip
            title={RESTORE_ACTION_TOOLTIP}
            placement="bottom"
            onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                event.stopPropagation();
            }}
        >
            <span>
                <IconButton
                    disabled={!canRestore}
                    onClick={onRestore}
                    color="secondary"
                    automation-id="file-row-restore-button"
                >
                    <FileRestoreIcon fontSize="small" />
                </IconButton>
            </span>
        </Tooltip>
    </Grid>
);

interface RecycleActionProps {
    onRestore: () => void;
    canRestore?: boolean;
}

interface RecycledFileRowProps {
    file: ListFile;
    fields: FieldDefinition[];
    canRestore: boolean;
}

export const RecycledFileRow = ({ file, fields, canRestore }: RecycledFileRowProps) => {
    const { ConfirmRestoreDialog, setRestoreDialogOpen, isRestoreItemHandlerLoading } =
        useRestoreItemHandler();
    const dispatch = useAppDispatch();
    const itemChecked = useAppSelector(isItemChecked(file.file_id));

    const handleCheck = (event: React.MouseEvent) => {
        event.stopPropagation();
        dispatch(toggleChecked({ id: file.file_id, name: file.name }));
    };

    if (isRestoreItemHandlerLoading) {
        return <TableSkeleton columns={5} rows={1} hasAction />;
    }

    return (
        <>
            <ListItem
                key={`${file.file_id}-${itemChecked}`}
                item={file}
                fields={fields}
                actions={
                    <RestoreAction
                        onRestore={() => setRestoreDialogOpen(true)}
                        canRestore={canRestore}
                    />
                }
                automation-id={`file-row-${file.file_id}-actions`}
                hasDisabledState
                checkbox={<Checkbox color="primary" onClick={handleCheck} checked={itemChecked} />}
            />
            <ConfirmRestoreDialog
                items={[{ id: file.file_id, name: file.name }]}
                itemType={ItemType.FILE}
            />
        </>
    );
};
