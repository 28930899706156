import type { ListObjectsResponse } from '@api/goose/dist/enhancedGooseClient';
import { useListObjectsQuery, type ListedObject } from '@api/goose/dist/enhancedGooseClient';
import { ErrorScreen } from '@local/svgs/dist/pageState';
import { RecycleBinEmptySvg } from '@local/svgs/dist/svg/RecycleBinEmptySvg';
import { SearchSvg } from '@local/svgs/dist/svg/SearchSvg';
import EmptyState from '@local/web-design-system-2/dist/components/EmptyState/EmptyState';
import type { FieldDefinition } from '@local/web-design-system-2/dist/components/GenericListing/types';
import { Order } from '@local/web-design-system-2/dist/components/GenericListing/types';
import type { HeadCell } from '@local/web-design-system-2/dist/components/SortedList/SortedList';
import { SortedList } from '@local/web-design-system-2/dist/components/SortedList/SortedList';
import TableSkeleton from '@local/web-design-system-2/dist/components/TableSkeleton/TableSkeleton';
import FileRestoreIcon from '@local/web-design-system-2/dist/icons/FileRestore';
import {
    CategoriesWorkspace,
    UserActionWorkspace,
} from '@local/workspaces/dist/WorkspaceMetrics.types';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import useTheme from '@mui/material/styles/useTheme';
import TablePagination from '@mui/material/TablePagination';
import Tooltip from '@mui/material/Tooltip';
import classNames from 'classnames';
import { useEffect } from 'react';

import { MessageSnackBar } from 'src/components/messageSnackBar/MessageSnackBar';
import { PaginatedList } from 'src/components/paginatedList/PaginatedList';
import { SearchField } from 'src/components/searchField/SearchField';
import { CheckedItemsToolbar } from 'src/components/toolBar/CheckedItemsToolbar';
import { useObjectSortParams } from 'src/hooks/useObjectSortParams';
import { usePagination } from 'src/hooks/usePagination';
import { useRestoreItemHandler } from 'src/hooks/useRestoreItemHandler';
import { TableHeader } from 'src/pages/workspacePage/workspaceContent/TableHeader';
import { checkAll, checkedSelection } from 'src/store/features/multiSelectSlice';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import {
    ENTER_OBJECT_NAME,
    NETWORK_ERROR_DESCR,
    NETWORK_ERROR_TITLE,
    RECYCLE_BIN_NO_OBJECTS,
    RECYCLE_BIN_NO_OBJECTS_DESC,
    NO_OBJECTS_FOUND,
    NO_SEARCH_RESULTS_DESC,
    SEARCH_LABEL,
} from 'src/strings';
import { ItemType } from 'src/types/ItemTypes';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS } from 'src/utils/pagination';

import { recycledObjectDefinition } from '../../workspacePage/workspaceContent/FieldRowDefinitions';
import { useStyles } from '../RecycleBinPage.styles';
import { RecycleBinTabs } from '../recycleBinTabs';
import { RecycledObjectRow } from './RecycledObjectRow';

interface RecycleBinObjectContentProps {
    orgId: string;
    workspaceId: string;
    canRestore: boolean;
}
export interface ListedObjectDisplay extends ListedObject {
    depth: number;
}
interface ObjectListProps {
    data: ListObjectsResponse | undefined;
    isFetching: boolean;
    fields: FieldDefinition[];
    canRestore: boolean;
    handleSort: (values: { key: string; order: Order }) => void;
    searchTerm: string;
}

const processRecycleObjectContents = (depth: number, contents?: ListedObject[]) => {
    if (!contents) {
        return [];
    }

    return contents.map(
        (object: ListedObject) =>
            ({
                ...object,
                depth,
            }) as ListedObjectDisplay,
    );
};

const ObjectList = ({
    data,
    isFetching,
    fields,
    canRestore,
    handleSort,
    searchTerm,
}: ObjectListProps) => {
    const theme = useTheme();

    const dispatch = useAppDispatch();
    const checkedList = useAppSelector(checkedSelection) as { id: string; name: string }[];

    const { ConfirmRestoreDialog, setRestoreDialogOpen, isRestoreItemHandlerLoading } =
        useRestoreItemHandler();

    const headCells: HeadCell<ListedObjectDisplay>[] = [
        ...fields.map((field) => ({
            id: field.key as keyof ListedObjectDisplay,
            label: field.label,
            sortable: !!field.sortFunction,
            sx: field.sx,
        })),
        {
            id: 'extraHeader' as keyof ListedObjectDisplay,
            label: '',
            sortable: false,
            sx: { width: '10%' },
        },
    ];

    const processedObjects = processRecycleObjectContents(0, data?.objects);

    const renderRow = (object: ListedObjectDisplay) => (
        <RecycledObjectRow
            object={object}
            key={object.object_id}
            fields={fields}
            canRestore={canRestore}
        />
    );

    const EmptyComponent = (
        <Grid container flexGrow={1} alignItems="center" justifyContent="center">
            <EmptyState
                title={searchTerm ? NO_OBJECTS_FOUND : RECYCLE_BIN_NO_OBJECTS}
                titleSx={{ py: 1 }}
                message={searchTerm ? NO_SEARCH_RESULTS_DESC : RECYCLE_BIN_NO_OBJECTS_DESC}
                messageSx={{ color: theme.palette.grey[700], py: 1 }}
                image={searchTerm ? <SearchSvg /> : <RecycleBinEmptySvg />}
                sx={{
                    paddingBottom: theme.spacing(14),
                    margin: 'auto',
                    width: '50%',
                }}
            />
        </Grid>
    );

    useEffect(
        () => () => {
            dispatch(checkAll([]));
        },
        [],
    );

    return (
        <>
            <MessageSnackBar />
            <SortedList
                data={processedObjects}
                headCells={headCells}
                renderRow={renderRow}
                defaultRowsPerPage={DEFAULT_PAGE_SIZE}
                isApiSort
                onSortChange={(key, newOrder) => {
                    dispatch(checkAll([]));
                    handleSort({
                        key: key as string,
                        order: newOrder === 'asc' ? Order.ASCENDING : Order.DESCENDING,
                    });
                }}
                defaultOrderBy={
                    fields.find((f) => f.defaultSortSettings)?.key as keyof ListedObjectDisplay
                }
                defaultOrder={
                    fields.find((f) => f.defaultSortSettings)?.defaultSortSettings?.order ===
                    Order.ASCENDING
                        ? 'asc'
                        : 'desc'
                }
                containerSx={{
                    table: { tableLayout: 'fixed' },
                    height: 'calc(100vh - 320px)',
                }}
                EmptyComponent={EmptyComponent}
                isLoading={isFetching || isRestoreItemHandlerLoading}
                LoadingComponent={
                    <PaginatedList
                        header={<TableHeader fields={fields} hasActions hasCheckbox />}
                        content={<TableSkeleton rows={5} columns={fields.length + 1} hasAction />}
                    />
                }
                multiselect
                ToolbarComponent={CheckedItemsToolbar({
                    dispatch,
                    numChecked: checkedList.length,
                    actions: [
                        {
                            key: 'restore',
                            action: (
                                <Tooltip title="Restore">
                                    <IconButton
                                        onClick={() => setRestoreDialogOpen(true)}
                                        disabled={!canRestore}
                                        automation-id="toolbar-restore-button"
                                    >
                                        <FileRestoreIcon />
                                    </IconButton>
                                </Tooltip>
                            ),
                        },
                    ],
                })}
            />
            <ConfirmRestoreDialog items={checkedList} itemType={ItemType.OBJECT} />
        </>
    );
};

export const RecycleBinObjectContent = ({
    orgId,
    workspaceId,
    canRestore,
}: RecycleBinObjectContentProps) => {
    const { classes } = useStyles();

    const { updateObjectSortParams, order, orderBy, key } = useObjectSortParams();

    const {
        page,
        pageSize,
        searchTerm,
        handleSearch,
        handleSetPage,
        handlePageSizeChange,
        setPaginationParams,
    } = usePagination();

    const fields = recycledObjectDefinition.map((field) =>
        field.key === key ? { ...field, defaultSortSettings: { order: order as Order } } : field,
    );

    const { data, isFetching, isError } = useListObjectsQuery({
        orgId,
        workspaceId,
        orderBy,
        deleted: true,
        offset: page * pageSize,
        limit: pageSize,
        ...(searchTerm !== '' && { objectName: [`ilike:*${searchTerm}*`] }),
    });

    const handleSort = (values: { key: string; order: Order }) => {
        if (order === values.order && key === values.key) {
            return;
        }
        updateObjectSortParams(values);
        setPaginationParams((currentParams) => ({ ...currentParams, page: 0 }));
    };

    useEffect(() => {
        setPaginationParams((currentParams) => ({ ...currentParams, page: 0 }));
    }, [searchTerm]);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        handleSetPage(newPage);
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const newPageSize = parseInt(event.target.value, 10);
        if (!Number.isNaN(newPageSize)) {
            handlePageSizeChange(newPageSize);
        }
    };

    if (isError || (!isFetching && !data)) {
        return <ErrorScreen msg={NETWORK_ERROR_TITLE} details={NETWORK_ERROR_DESCR} />;
    }

    return (
        <Stack>
            <Stack
                direction="row"
                sx={{ paddingLeft: '16px' }}
                justifyContent="space-between"
                automation-id="pagination-menu"
            >
                <TablePagination
                    page={page}
                    component="div"
                    rowsPerPage={pageSize}
                    labelRowsPerPage="Show"
                    count={data?.total ?? 0}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={DEFAULT_PAGE_SIZE_OPTIONS}
                    classes={{ toolbar: classes.pagination }}
                    sx={{
                        fontSize: '10px',
                        '.MuiTablePagination-displayedRows': {
                            fontSize: '10px',
                        },
                        width: '100%',
                        display: 'flex',
                        justifyItems: 'start',
                    }}
                />
                <Grid container justifyContent="end" gap={3}>
                    <RecycleBinTabs />
                    <SearchField
                        color="secondary"
                        variant="outlined"
                        defaultValue={searchTerm}
                        sx={{ maxWidth: '220px' }}
                        onSearchCallBack={(value) => handleSearch(value, true)}
                        placeholder={ENTER_OBJECT_NAME}
                        automation-id="recyclebin-search"
                        InputProps={{ sx: { maxHeight: '40px' } }}
                        userAction={UserActionWorkspace.WORKSPACE_OBJECTS_SEARCH}
                        userActionCategory={CategoriesWorkspace.WORKSPACE_OBJECTS_PAGE}
                        label={SEARCH_LABEL}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
            </Stack>
            <div className={classNames([classes.table])}>
                <ObjectList
                    data={data}
                    isFetching={isFetching}
                    fields={fields}
                    canRestore={canRestore}
                    handleSort={handleSort}
                    searchTerm={searchTerm}
                />
            </div>
        </Stack>
    );
};
