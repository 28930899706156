import packageJson from '../package.json';

const { version } = packageJson;

/**
 * Errors
 */

export const NO_INTERNET_CONNECTION = 'There is no internet connection';
export const ERROR_BOUNDARY_MESSAGE = 'Something has gone wrong.';
export const ERROR_BOUNDARY_DETAILS =
    'Try reloading the page, checking the connection, proxy or firewall settings. Otherwise, contact the system admin.';
export const ERROR_FORBIDDEN_CONNECTION =
    "Your current network has not been granted permission to access this resource. Please connect to your organization's network or VPN and try again.";
export const ERROR_FORBIDDEN_CONNECTION_MESSAGE = '403 Forbidden Connection';

/**
 * Common
 */

export const CLOSE = 'Close';
export const CANCEL = 'Cancel';
export const COPY = 'Copy ID';
export const BACK = 'Back';
export const DELETE = 'Delete';
export const RECYCLE = 'Recycle';
export const DOWNLOAD = 'Download';
export const BACK_TO_WORKSPACES = 'Back to workspaces';
export const BACK_TO_USERS = 'Back to users';
export const BACK_TO_ADMINISTRATORS = 'Back to administrators';
export const WORKSPACES_AND_PERMISSIONS = 'Workspaces and permissions';
export const COPIED_TO_CLIPBOARD = 'Copied to clipboard!';
export const DRAG_AND_DROP = 'Drag and drop files to upload';

/**
 * Services
 */

export const BLOCKMODEL_SERVICE = 'blockmodel';
export const EVO_SERVICE = 'evo';

/**
 * Landing Page
 */

// Hero
export const LEARN_MORE_LINK = 'Learn more &nbsp;›';
export const OVERVIEW = 'Overview';
export const CONTACT_US = 'Contact us';

// CardList
export const CARD_LIST_TITLE = 'Cloud applications, powered by Seequent Evo';
export const CARD_LIST_DESCRIPTION =
    'Explore a host of cloud Applications, available now and coming soon. Learn more, request a trial, or access now. Simply login to discover your options.';

// Card
export const LOGIN = 'Login';
export const EAP_LOGIN = 'EAP login';
export const LEARN_MORE_BUTTON = 'Learn more';
export const REQUEST_A_TRIAL = 'Request a trial';
export const REQUEST_A_DEMO = 'Request a demo';
export const REGISTER_INTEREST = 'Register interest';

// Release Status
export const COMING_SOON = 'Coming soon';

// LearnMore
export const REQUEST_TRIAL = 'Request trial';

// Header
export const LOGIN_TO_WORKSPACES = 'Login';

// Footer
export const FOOTER_SLOGAN_1 = 'Powered by';
export const FOOTER_SLOGAN_2 = 'Seequent Evo';
export const FOOTER_DEVELOPERS = 'Developers';
export const FOOTER_CONTACT_US = 'Contact us';
export const FOOTER_SEEQUENT_URL = 'Seequent.com';
export const FOOTER_SEEQUENT_COPYWRIGHT =
    '©2022 Seequent, The Bentley Subsurface Company. All rights reserved';

// enquire evo
export const EVO_ENQUIRE = 'Enquire about Seequent Evo';
export const EVO_ENQUIRE_SUBTEXT =
    "Whether you're a customer wanting to connect your solutions, or a partner wanting to build a unique integration. ";
export const EVO_ENQUIRE_SUBTEXT2 = ' to get involved.';
export const EVO_ENQUIRE_LINK = 'https://www.seequent.com/company/seequent-evo/enquire/';

/**
 * Authenticated View
 */

// Scaffolding
export const TAGLINE = '© Seequent, The Bentley Subsurface Company';
export const VERSION_LABEL = `Version ${version}`;
export const VERSION = `${version}`;
export const SCAFFOLDING_TITLE = 'It all starts with the data';

// Login related errors
export const LOGIN_FAILED = 'Login failed';
export const LOG_IN_AGAIN = 'Please log in again, or contact Seequent if this continues';

// workspace not found
export const WORKSPACE_NOT_FOUND_TITLE = 'Workspace not found';
export const WORKSPACE_NOT_FOUND_MESSAGE =
    'Workspace may have been removed, or you do not have access.';

// 404 page
export const NOT_FOUND_TITLE = 'Page not found';
export const USER_NOT_FOUND_TITLE = 'User not found';
export const NOT_FOUND_MESSAGE = 'Double check the URL or try again later';

// Empty search results
export const NO_OBJECTS_FOUND = 'No objects found';
export const NO_FILES_FOUND = 'No files found';
export const NO_SEARCH_RESULTS_DESC = 'Try using different keywords or filters';

// workspace page
export const NETWORK_ERROR_TITLE = 'Something went wrong';
export const NETWORK_ERROR_DESCR = 'If this problem persists, please contact Support';
export const WORKSPACE_CONTENT_NO_CONTENT = 'No geoscience objects';
export const WORKSPACE_CONTENT_NO_CONTENT_DESC =
    'Upload objects to share them with everyone in your team';

// Users page
export const ADMINISTRATORS_TITLE = 'Administrators';
export const RESTRICTED_NO_PERMISSION_USERS = "You don't have permission to edit users.";

// User page
export const EDIT_USER_TITLE = 'Edit user';
export const USER_DETAILS = 'User details';
export const USER_SAVE = 'Save';
export const USER_DELETE_FAIL = 'Failed to remove user';
export const USER_DELETE_SUCCESS = 'Successfully removed user';
export const USER_WORKSPACE_DETAILS = 'Workspace permissions';
export const USER_ADMIN = 'Admin';
export const USER_BLOCKSYNC = 'BlockSync';
export const USER_UPDATE_SUCCESS = 'Successfully updated user roles';
export const USER_UPDATE_FAIL = 'Failed to update user roles';
export const ADMIN_CANNOT_SELF_DELETE =
    'Admins cannot remove themselves, please ask another admin to do so';
export const ADMIN_TOOLTIP =
    'Evo admins can manage Evo app licenses and configure workspaces at an organisational level.';
export const USER_NO_WORKSPACES = 'No workspaces';
export const USER_NO_WORKSPACES_MESSAGE = 'User is not a member of any workspaces yet';
export const FAILED_TO_ADD_WORKSPACES =
    'Failed to add user permissions to some workspaces, please try again later.';
export const FAILED_TO_LOAD_WORKSPACES =
    'Failed to load workspace list, you will be unable to add user permissions to workspaces, please try again later.';
export const ASSIGN_WORKSPACES = 'Assign workspaces';
export const ENTER_WORKSPACE = 'Enter a workspace';
export const NO_WORKSPACES_FOUND = 'No workspaces found';
export const REMOVE_WORKSPACE_MESSAGE =
    'Failed to remove user from workspace, please try again later';

// Instance Users Page
export const INSTANCE_USERS_TITLE = 'Users';
export const INSTANCE_USERS_HEADER_NAME = 'Name';
export const INSTANCE_USERS_HEADER_EMAIL = 'Email';
export const INSTANCE_USERS_HEADER_ROLE = 'Role';
export const INSTANCE_USERS_EMPTY_TITLE = 'No users';
export const INSTANCE_USERS_EMPTY_MESSAGE = 'No users found, or you may have exceeded all pages.';

// File uploads
export const ERROR_UPLOADING_FILE = 'Failed to upload file, please try again';
export const CANCEL_UPLOADING_FILE = 'File upload cancelled';

// Workspace Contents Page
export const GEOSCIENCE_OBJECTS = 'Geoscience objects';

// Sidebar headers
export const FILE_PROPERTIES = 'File properties';
export const PROPERTIES = 'Properties';
export const METADATA = 'Metadata';
export const FILE_HISTORY = 'File history';
export const VERSION_HISTORY = 'Version history';
export const WEBVIZ_BUTTON = 'View object';
export const WEBVIZ_BUTTON_DISCOVERY = 'Open in 3D Viewer';
export const OBJECT_OPEN_IN = 'Open in';
export const FILTER_PROPERTIES = 'Filter properties';

// Object & File Properties in Sidebar
export const CREATED_ON = 'Created on';
export const OBJECT_PATH = 'Object path';
export const TYPE = 'Type';
export const ID = 'ID';
export const DATA_TYPE = 'Data type';
export const FILE_SIZE = 'File size';
export const FILE_VERSION = 'Version';
export const LAST_MODIFIED = 'Last modified';
export const MODIFIED_BY = 'Modified by';
export const CREATED_BY = 'Created by';
export const VERSION_ID = 'Version ID';
export const VERSION_WITH_ID = 'Version {id}';
export const OBJECT_ID = 'Object ID';
export const UPLOAD_NEW_VERSION = 'Upload new version';
export const NO_SELECTED_OBJECT = 'Select an object to see more details';
export const STAGE = 'Stage';

// Folder filter properties in Sidebar
export const FILTER_NAME = 'Filter name';
export const FILTER_DESCRIPTION = 'Filter description';

// Object Metadata in sidebar
export const SOURCE = 'Source';
export const TAGS = 'Tags';

// Affinity Dropdown
export const VIEW = 'View by: ';

// Empty folder row
export const EMPTY_FOLDER_MESSAGE = 'This folder is empty';
export const OBJECT_NOT_FOUND_TITLE = 'Geoscience object not found';
export const OBJECT_NOT_FOUND_MESSAGE = 'Object has been removed';

// Visualization page
export const ID_PARAM = 'id';
export const PLOT = 'Plot';
export const ROOT_TREE_ID = 'ROOT_TREE_ID';

// Workspaces -> Files page
export const FILES = 'Files';
export const SEARCH_LABEL = 'Search';
export const SEARCH_PLACEHOLDER = 'Files';
export const RECYCLE_FILE_CONFIRM = 'Recycle';
export const RECYCLE_FILE_CANCEL = 'Cancel';
export const RECYCLE_FILE_DIALOG_TITLE = 'Recycle file';
export const RECYCLE_FILE_DIALOG_CONTENT = 'Do you wish to recycle';
export const RECYCLE_FILE_DIALOG_WARNING = 'It can be restored from the recycle bin.';
export const RECYCLE_FILE_SUCCESS = 'File recycled successfully';
export const RECYCLE_FILE_FAILED = 'Failed to recycle file, please try again later.';
export const DOWNLOAD_FILE_FAILED = 'Failed to download file, please try again later.';
export const GET_FILE_FAILED = 'File not found, please try again.';
export const GET_FILE_FAILED_GONE = 'Sorry, this file has been deleted.';
export const UPLOAD_FILES = 'Upload files';
export const INSUFFICIENT_WORKSPACE_PERMISSION =
    'You do not have permission to modify this workspace';
export const FILES_CONTENT_NO_CONTENT = 'No files';
export const FILES_CONTENT_NO_CONTENT_DESC =
    'Publish some files, or request to be added to the workspace';
export const NO_SELECTED_FILE = 'Select a file to see more details';

// Files page Table Headers
export const FILES_HEADER_NAME = 'Name';
export const FILES_HEADER_DATA_TYPE = 'Data type';
export const FILES_HEADER_FILE_SIZE = 'File size';
export const FILES_HEADER_LAST_MODIFIED = 'Last modified';
export const FILES_HEADER_MODIFIED_BY = 'Modified by';
export const FILES_HEADER_DELETED_BY = 'Recycled by';
export const FILES_HEADER_DELETED_AT = 'Date recycled';

// Objects page Table Headers
export const OBJECT_HEADER_NAME = 'Name';
export const OBJECT_HEADER_DATA_TYPE = 'Data type';
export const OBJECT_HEADER_LAST_MODIFIED = 'Last modified';
export const OBJECT_HEADER_MODIFIED_BY = 'Modified by';
export const OBJECT_HEADER_STAGE = 'Stage';
export const OBJECT_HEADER_DELETED_BY = 'Recycled by';
export const OBJECT_HEADER_DELETED_AT = 'Date recycled';

// Recycled Objects page Table Headers
export const OBJECT_HEADER_WORKSPACE = 'Workspace';

// Objects page Filter
export const FILTER_OBJECTS_LABEL = 'Filter';
export const FILTER_OBJECTS_PLACEHOLDER = 'Objects';
export const FILTERS = 'Filters';
export const FILTER_BY_DATA_TYPE = 'By data type';
export const FILTER_BY_SOURCE = 'By source';
export const FILTER_BY_UPDATED_ON = 'Last modified';
export const FILTER_BY_UPDATED_BY = 'Modified by';
export const SEARCH_FOR_DATATYPES = 'Search for data types';
export const UPDATED_ON_PLACEHOLDER = 'dd/mm/yyyy - dd/mm/yyyy';
export const SEARCH_FOR_SOURCES = 'Search for sources';
export const SEARCH_FOR_USERS = 'Search for users';
export const CLEAR_ALL = 'Clear filters';
export const APPLY_FILTERS = 'Apply filters';
export const UNKNOWN_USERNAME = 'Unknown username';
export const FILTER_NO_RESULTS = 'No results';
export const SELECT_DATE = 'Select date';

// Objects page Search
export const SEARCH_OBJECTS = 'Search';
export const ENTER_OBJECT_NAME = 'Object name';

// Objects page actions
export const RECYCLE_OBJECT_ACTION = 'Recycle';
export const OPEN_IN_VIEWER_ACTION = 'View object';
export const OPEN_IN_DRIVER_ACTION = 'Open in Driver';
export const OPEN_IN_BLOCK_SYNC_ACTION = 'Open in BlockSync';

// Objects page dialog
export const RECYCLE_DIALOG_TITLE = 'Recycle object';
export const RECYCLE_DIALOG_CONFIRM = 'Recycle';
export const RECYCLE_DIALOG_CANCEL = 'Cancel';
export const RECYCLE_DIALOG_CONTENT = 'Do you wish to recycle';
export const RECYCLE_DIALOG_WARNING = 'It can be restored from the recycle bin.';
export const RECYCLE_TOAST_SUCCESS = 'Object recycled successfully';
export const RECYCLE_TOAST_FAILURE = 'Failed to recycle object';
export const UNDO = 'Undo';
export const TRY_AGAIN = 'Try again';
export const FAILED_TO_RECYCLE = 'Failed to recycle';
export const FAILED_TO_RESTORE = 'Failed to restore';
export const VIEW_RECYCLED_OBJECTS = 'View object';
export const VIEW_RECYCLED_FILE = 'View file';

export const RESTORE_OBJECT_DIALOG_TITLE = 'Restore object';
export const RESTORE_FILE_DIALOG_TITLE = 'Restore file';
export const RESTORE_DIALOG_CONFIRM = 'Restore';
export const RESTORE_DIALOG_CANCEL = 'Cancel';
export const RESTORE_DIALOG_CONTENT = 'Do you wish to restore';
export const RESTORE_OBJECT_DIALOG_WARNING =
    'This object will be restored to the geoscience objects list.';
export const RESTORE_FILE_DIALOG_WARNING = 'This file will be restored to the files list.';
export const RESTORE_OBJECT_TOAST_FAILURE = 'Failed to restore object';
export const RESTORE_FILE_TOAST_FAILURE = 'Failed to restore file';
export const RESTORE_OBJECT_TOAST_SUCCESS = 'Object restored successfully';
export const RESTORE_FILE_TOAST_SUCCESS = 'File restored successfully';
export const VIEW_RESTORED_OBJECT = 'View object';
export const VIEW_RESTORED_FILE = 'View file';
export const RESTORE_ACTION_TOOLTIP = 'Restore';

// Multi select dialogs and toasts
export const MULTI_RECYCLE_OBJECT_DIALOG_TITLE = 'Recycle objects';
export const MULTI_RECYCLE_FILES_DIALOG_TITLE = 'Recycle files';
export const MULTI_RESTORE_OBJECT_DIALOG_TITLE = 'Restore objects';
export const MULTI_RESTORE_FILES_DIALOG_TITLE = 'Restore files';

export const MULTI_RECYCLE_DIALOG_CONFIRM = 'Recycle';
export const MULTI_RECYCLE_DIALOG_CANCEL = 'Cancel';
export const MULTI_RECYCLE_DIALOG_CONTENT = 'Do you wish to recycle';
export const MULTI_RECYCLE_DIALOG_WARNING = 'These can be restored from the recycle bin.';

export const MULTI_RESTORE_DIALOG_CONFIRM = 'Restore';
export const MULTI_RESTORE_DIALOG_CANCEL = 'Cancel';
export const MULTI_RESTORE_DIALOG_CONTENT = 'Do you wish to restore';

export const MULTI_RESTORE_OBJECT_DIALOG_WARNING =
    'These will be restored to the geoscience objects list.';
export const MULTI_RESTORE_FILE_DIALOG_WARNING = 'These will be restored to the files list.';

export const MULTI_RECYCLE_OBJECT_TOAST_SUCCESS = 'Objects recycled successfully';
export const MULTI_RECYCLE_OBJECT_TOAST_FAILURE = 'Failed to recycle one or more objects';
export const MULTI_RECYCLE_FILE_TOAST_SUCCESS = 'Files recycled successfully';
export const MULTI_RECYCLE_FILE_TOAST_FAILURE = 'Failed to recycle one or more files';

export const MULTI_VIEW_OBJECTS = 'View objects';
export const MULTI_VIEW_FILES = 'View files';

export const MULTI_RESTORE_OBJECT_TOAST_FAILURE = 'Failed to restore one or more objects';
export const MULTI_RESTORE_OBJECT_TOAST_SUCCESS = 'Objects restored successfully';
export const MULTI_RESTORE_FILE_TOAST_FAILURE = 'Failed to restore one or more files';
export const MULTI_RESTORE_FILE_TOAST_SUCCESS = 'Files restored successfully';

export const TOOLBAR_SELECTED = 'selected';

// Object stages dialog
export const STAGE_DIALOG_FAILURE = 'Failed to update object stage';
export const STAGE_DIALOG_SUCCESS = 'Object stage updated successfully';

// Upload container
export const UPLOADING_FILES = 'Uploading files';
export const UPLOAD_MORE_FILES = 'Upload more files';

// Search page
export const SEARCH_TITLE = 'Search';
export const NO_ACCESS_DESCRIPTION =
    'This object is in a workspace that you do not have access to. Please request access from the workspace owner.';
export const METADATA_FIELD_TYPE = 'Type';
export const METADATA_FIELD_CREATED_BY = 'Created by';
export const METADATA_FIELD_CREATED_ON = 'Created on';
export const METADATA_FIELD_MODIFIED_BY = 'Modified by';
export const METADATA_FIELD_LAST_MODIFIED = 'Last modified';
export const METADATA_FIELD_WORKSPACE = 'Workspace';
export const METADATA_FIELD_CRS = 'CRS';
export const METADATA_DESCRIPTION_TITLE = 'Description';
export const METADATA_DETAILS_TITLE = 'Details';
export const DRAW_LAYER = 'Draw layer';
export const CLEAR_DRAWN_LAYERS = 'Clear drawn layers';

// Search custom tooltip
export const GLOBAL_MAP = 'Global map';
export const DRAW_LAYER_TOOLTIP = 'Draw your preferred data boundary';

// Recycle Bin Page
export const RECYCLE_BIN_TITLE = 'Recycle bin';
export const RECYCLE_BIN_NO_OBJECTS = 'No objects in the recycle bin yet';
export const RECYCLE_BIN_NO_FILES = 'No files in the recycle bin yet';
export const GEOSCIENCE_OBJECTS_NICK = 'Objects';
export const ENTER_FILE_NAME = 'File name';

export const RECYCLE_BIN_NO_OBJECTS_DESC =
    'Objects that have been recycled will appear here, you will be able to restore them.';
export const RECYCLE_BIN_NO_FILES_DESC =
    'Files that have been recycled will appear here, you will be able to restore them.';
export const RECYCLE_BIN_OBJECTS_TITLE = 'Geoscience objects';
export const RECYCLE_BIN_FILES_TITLE = 'Files';

// Recycled Objects page actions
export const RESTORE_OBJECT_ACTION = 'Restore';

// Admin Settings Page
export const SETTINGS_TITLE = 'Settings';
export const MACHINE_LEARNING = 'Machine learning';
export const ML_HEADER_TEXT = {
    enabled: 'Machine learning is enabled for your organisation.',
    disabled: 'Machine learning is not enabled for your organisation.',
};
export const ML_DESC_TEXT_1 = {
    enabled:
        'To disable machine learning for your organisation, please reach out to your Seequent account manager or ',
    disabled:
        'To enable machine learning for your organisation, please reach out to your Seequent account manager or ',
};
export const ML_DESC_CONTACT_US = 'contact us.';
export const ML_DESC_TEXT_2 = {
    enabled:
        'You can turn on or off individual workspaces here. If the workspace is turned off then data won’t be shared with Seequent for machine learning. Only administrators can view and adjust these settings.',
    disabled:
        'When machine learning is enabled, you can turn on or off individual workspaces here. If the workspace is turned off then data won’t be shared with Seequent for machine learning. Only administrators can view and adjust these settings.',
};

// Admin Settings Page - Workspace List Table
export const TABLE_HEADER_COL_1 = 'Enable workspaces';
export const TABLE_HEADER_COL_2 = 'Created by';
export const TABLE_HEADER_COL_3 = 'Last modified';
export const ML_EMPTY_STATE_PAGE_TEXT = 'No workspaces yet';
export const ML_EMPTY_STATE_PAGE_CTA = 'Create a workspace to get started';
export const ERROR_PAGE_TITLE = 'Oops!';
export const ERROR_PAGE_SUBTITLE = 'Internal server error';
export const ERROR_PAGE_DESCRIPTION_TEXT_1 = 'There has been an internal server error.';
export const ERROR_PAGE_DESCRIPTION_TEXT_2 =
    'We are currently unable to display the data you are looking for.';
export const ERROR_PAGE_BUTTON = 'Try again';
export const NOTIFICATION_MESSAGE_1 = 'Workspace enabled for machine learning';
export const NOTIFICATION_MESSAGE_2 = 'Workspace disabled for machine learning';
export const FAIL_ML_STATE_CHANGE_MESSAGE = "Failed to change workspace's machine learning state.";

// File-API request errors
export const FILE_API_ERROR_DELETED = 'File has been deleted in the background';
export const FILE_API_ERROR_WORKSPACE_NOT_FOUND =
    'Access to this workspace has been denied. Please reload the page and try again';
export const FILE_API_ERROR_INITIAL_UPLOAD_FAILED = 'Failed to upload file to file service';

// Cookie settings
export const USER_COOKIE_SETTINGS = 'Cookie settings';

// Cesium strings
export const OBJECTS = 'Objects';
export const RESULTS = 'Results';
export const ZOOM_IN = 'Zoom in';
export const ZOOM_OUT = 'Zoom out';
export const RESET_ZOOM = 'Reset zoom';
export const PLAY = 'Play';
export const TOGGLE_3D = 'Toggle to 3D mode';
export const TOGGLE_2D = 'Toggle to 2D mode';
export const THREE_DIMENSION = '3D';
export const TWO_DIMENSION = '2D';
export const SHOW_MORE = 'Show more';

// BlockSync strings
export const BLOCKSYNC_DELETE_DISABLED_MESSAGE = 'BlockSync reference objects cannot be recycled';
export const BLOCKSYNC_RESTORE_DISABLED_MESSAGE = 'BlockSync reference objects cannot be restored';
export const BLOCKSYNC_SCHEMA = 'Blocksync reference';
