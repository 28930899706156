import type { ListedObject } from '@api/goose/dist/enhancedGooseClient';
import { extractObjectSchemaName } from '@api/goose/dist/utils/extractObjectSchemaName';
import { ListItem } from '@local/web-design-system-2/dist/components/GenericListing/ListItem';
import type { FieldDefinition } from '@local/web-design-system-2/dist/components/GenericListing/types';
import TableSkeleton from '@local/web-design-system-2/dist/components/TableSkeleton/TableSkeleton';
import FileRestoreIcon from '@local/web-design-system-2/dist/icons/FileRestore';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { useRestoreItemHandler } from 'src/hooks/useRestoreItemHandler';
import { isItemChecked, toggleChecked } from 'src/store/features/multiSelectSlice';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import {
    BLOCKSYNC_RESTORE_DISABLED_MESSAGE,
    BLOCKSYNC_SCHEMA,
    RESTORE_ACTION_TOOLTIP,
} from 'src/strings';
import { ItemType } from 'src/types/ItemTypes';

const RestoreAction = ({ onRestore, canRestore, shouldRender = true }: RecycleActionProps) =>
    shouldRender && (
        <Grid
            gap={2}
            container
            alignItems="center"
            justifyContent="center"
            item
            xs
            automation-id="object-row-restore-action"
        >
            <Tooltip
                title={RESTORE_ACTION_TOOLTIP}
                placement="bottom"
                onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                    event.stopPropagation();
                }}
            >
                <span>
                    <IconButton
                        disabled={!canRestore}
                        onClick={onRestore}
                        color="secondary"
                        automation-id="object-row-restore-button"
                    >
                        <FileRestoreIcon fontSize="small" />
                    </IconButton>
                </span>
            </Tooltip>
        </Grid>
    );

interface RecycleActionProps {
    onRestore: () => void;
    canRestore?: boolean;
    shouldRender?: boolean;
}

interface RecycledObjectRowProps {
    object: ListedObject;
    fields: FieldDefinition[];
    canRestore: boolean;
}

export const RecycledObjectRow = ({ object, fields, canRestore }: RecycledObjectRowProps) => {
    const { ConfirmRestoreDialog, setRestoreDialogOpen, isRestoreItemHandlerLoading } =
        useRestoreItemHandler();

    const dispatch = useAppDispatch();

    const itemChecked = useAppSelector(isItemChecked(object.object_id));
    const handleCheck = (event: React.MouseEvent) => {
        event.stopPropagation();
        dispatch(toggleChecked({ id: object.object_id, name: object.name }));
    };

    if (isRestoreItemHandlerLoading) {
        return <TableSkeleton columns={5} rows={1} hasAction />;
    }

    return (
        <>
            <ListItem
                item={object}
                key={`${object.object_id}-${itemChecked}`}
                fields={fields}
                automation-id="recycled-object-row"
                actions={
                    <RestoreAction
                        onRestore={() => setRestoreDialogOpen(true)}
                        canRestore={canRestore}
                        shouldRender={extractObjectSchemaName(object.schema) !== BLOCKSYNC_SCHEMA}
                    />
                }
                checkbox={
                    <Tooltip
                        title={
                            extractObjectSchemaName(object.schema) === BLOCKSYNC_SCHEMA
                                ? BLOCKSYNC_RESTORE_DISABLED_MESSAGE
                                : null
                        }
                        onClick={(event) => event.stopPropagation()}
                    >
                        <span>
                            <Checkbox
                                color="primary"
                                onClick={handleCheck}
                                checked={itemChecked}
                                disabled={
                                    extractObjectSchemaName(object.schema) === BLOCKSYNC_SCHEMA
                                }
                            />
                        </span>
                    </Tooltip>
                }
                hasDisabledState
            />

            <ConfirmRestoreDialog
                items={[{ id: object.object_id, name: object.name }]}
                itemType={ItemType.OBJECT}
            />
        </>
    );
};
