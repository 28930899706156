import { ErrorScreen } from '@local/svgs/dist/pageState';
import { RecycleBinEmptySvg } from '@local/svgs/dist/svg/RecycleBinEmptySvg';
import { SearchSvg } from '@local/svgs/dist/svg/SearchSvg';
import EmptyState from '@local/web-design-system-2/dist/components/EmptyState/EmptyState';
import type { FieldDefinition } from '@local/web-design-system-2/dist/components/GenericListing/types';
import { Order } from '@local/web-design-system-2/dist/components/GenericListing/types';
import type { HeadCell } from '@local/web-design-system-2/dist/components/SortedList/SortedList';
import { SortedList } from '@local/web-design-system-2/dist/components/SortedList/SortedList';
import TableSkeleton from '@local/web-design-system-2/dist/components/TableSkeleton/TableSkeleton';
import FileRestoreIcon from '@local/web-design-system-2/dist/icons/FileRestore';
import {
    CategoriesWorkspace,
    UserActionWorkspace,
} from '@local/workspaces/dist/WorkspaceMetrics.types';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import TablePagination from '@mui/material/TablePagination';
import Tooltip from '@mui/material/Tooltip';
import classNames from 'classnames';
import { useEffect } from 'react';

import { useListFilesQuery } from 'src/apiClients/enhancedFileMiddleware';
import type { ListFile } from 'src/apiClients/GENERATED_fileClientEndpoints';
import { MessageSnackBar } from 'src/components/messageSnackBar/MessageSnackBar';
import { PaginatedList } from 'src/components/paginatedList/PaginatedList';
import { SearchField } from 'src/components/searchField/SearchField';
import { CheckedItemsToolbar } from 'src/components/toolBar/CheckedItemsToolbar';
import { usePagination } from 'src/hooks/usePagination';
import { useRestoreItemHandler } from 'src/hooks/useRestoreItemHandler';
import { recycledFileDefinition } from 'src/pages/workspacePage/workspaceContent/FieldRowDefinitions';
import { TableHeader } from 'src/pages/workspacePage/workspaceContent/TableHeader';
import { checkAll, checkedSelection } from 'src/store/features/multiSelectSlice';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import {
    ENTER_FILE_NAME,
    NETWORK_ERROR_DESCR,
    NETWORK_ERROR_TITLE,
    RECYCLE_BIN_NO_FILES,
    RECYCLE_BIN_NO_FILES_DESC,
    NO_FILES_FOUND,
    NO_SEARCH_RESULTS_DESC,
    SEARCH_LABEL,
} from 'src/strings';
import { ItemType } from 'src/types/ItemTypes';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS } from 'src/utils/pagination';

import { useStyles } from '../RecycleBinPage.styles';
import { RecycleBinTabs } from '../recycleBinTabs';
import { RecycledFileRow } from './RecycledFileRow';

interface RecycleBinFileContentProps {
    orgId: string;
    workspaceId: string;
    canRestore: boolean;
}

interface FileListProps {
    files: ListFile[];
    isFetching: boolean;
    fields: FieldDefinition[];
    canRestore: boolean;
    handleSort: () => void;
    searchTerm: string;
}

const FileList = ({
    files,
    isFetching,
    fields,
    canRestore,
    handleSort,
    searchTerm,
}: FileListProps) => {
    const theme = useTheme();

    const dispatch = useAppDispatch();
    const checkedList = useAppSelector(checkedSelection) as { id: string; name: string }[];
    const { ConfirmRestoreDialog, setRestoreDialogOpen, isRestoreItemHandlerLoading } =
        useRestoreItemHandler();

    const headCells: HeadCell<ListFile>[] = [
        ...fields.map((field) => ({
            id: field.key as keyof ListFile,
            label: field.label,
            sortable: !!field.sortFunction,
        })),
        {
            id: 'extraHeader' as keyof ListFile,
            label: '',
            sortable: false,
            sx: { width: '10%' },
        },
    ];

    const renderRow = (file: ListFile) => (
        <RecycledFileRow file={file} key={file.file_id} fields={fields} canRestore={canRestore} />
    );

    const EmptyComponent = (
        <Grid container flexGrow={1} alignItems="center" justifyContent="center">
            <EmptyState
                title={searchTerm ? NO_FILES_FOUND : RECYCLE_BIN_NO_FILES}
                titleSx={{ py: 1 }}
                message={searchTerm ? NO_SEARCH_RESULTS_DESC : RECYCLE_BIN_NO_FILES_DESC}
                messageSx={{ color: theme.palette.grey[700], py: 1 }}
                image={searchTerm ? <SearchSvg /> : <RecycleBinEmptySvg />}
                sx={{
                    paddingBottom: theme.spacing(14),
                    margin: 'auto',
                    width: '50%',
                }}
            />
        </Grid>
    );

    useEffect(
        () => () => {
            dispatch(checkAll([]));
        },
        [],
    );

    return (
        <>
            <MessageSnackBar />
            <SortedList
                data={files}
                headCells={headCells}
                renderRow={renderRow}
                defaultRowsPerPage={DEFAULT_PAGE_SIZE}
                onSortChange={() => {
                    dispatch(checkAll([]));
                    handleSort();
                }}
                defaultOrderBy={fields.find((f) => f.defaultSortSettings)?.key as keyof ListFile}
                defaultOrder={
                    fields.find((f) => f.defaultSortSettings)?.defaultSortSettings?.order ===
                    Order.ASCENDING
                        ? 'asc'
                        : 'desc'
                }
                containerSx={{ height: 'calc(100vh - 320px)', table: { tableLayout: 'fixed' } }}
                EmptyComponent={EmptyComponent}
                isLoading={isFetching || isRestoreItemHandlerLoading}
                LoadingComponent={
                    <PaginatedList
                        header={<TableHeader fields={fields} hasActions hasCheckbox />}
                        content={<TableSkeleton rows={5} columns={fields.length + 1} hasAction />}
                    />
                }
                multiselect
                ToolbarComponent={CheckedItemsToolbar({
                    dispatch,
                    numChecked: checkedList.length,
                    actions: [
                        {
                            key: 'restore',
                            action: (
                                <Tooltip title="Restore">
                                    <IconButton
                                        onClick={() => setRestoreDialogOpen(true)}
                                        disabled={!canRestore}
                                        automation-id="toolbar-restore-button"
                                    >
                                        <FileRestoreIcon />
                                    </IconButton>
                                </Tooltip>
                            ),
                        },
                    ],
                })}
            />
            <ConfirmRestoreDialog items={checkedList} itemType={ItemType.FILE} />
        </>
    );
};

export const RecycleBinFileContent = ({
    orgId,
    workspaceId,
    canRestore,
}: RecycleBinFileContentProps) => {
    const { classes } = useStyles();

    const {
        page,
        pageSize,
        searchTerm,
        handleSearch,
        handleSetPage,
        handlePageSizeChange,
        setPaginationParams,
    } = usePagination();

    const fields = recycledFileDefinition;

    const { data, isFetching, isError } = useListFilesQuery({
        organisationId: orgId,
        workspaceId,
        limit: pageSize,
        offset: page * pageSize,
        deleted: true,
        ...(searchTerm !== '' && { fileName: searchTerm }),
    });

    useEffect(() => {
        setPaginationParams((currentParams) => ({ ...currentParams, page: 0 }));
    }, [searchTerm]);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        handleSetPage(newPage);
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const newPageSize = parseInt(event.target.value, 10);
        if (!Number.isNaN(newPageSize)) {
            handlePageSizeChange(newPageSize);
        }
    };

    const handleSort = () => {
        setPaginationParams((currentParams) => ({ ...currentParams, page: 0 }));
    };

    if (isError || (!isFetching && !data)) {
        return <ErrorScreen msg={NETWORK_ERROR_TITLE} details={NETWORK_ERROR_DESCR} />;
    }

    return (
        <Stack>
            <Stack
                direction="row"
                sx={{ paddingLeft: '16px' }}
                justifyContent="space-between"
                automation-id="pagination-menu"
            >
                <TablePagination
                    page={page}
                    component="div"
                    rowsPerPage={pageSize}
                    labelRowsPerPage="Show"
                    count={data?.total ?? 0}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={DEFAULT_PAGE_SIZE_OPTIONS}
                    classes={{ toolbar: classes.pagination }}
                    sx={{
                        fontSize: '10px',
                        '.MuiTablePagination-displayedRows': {
                            fontSize: '10px',
                        },
                        width: '100%',
                        display: 'flex',
                        justifyItems: 'start',
                    }}
                />

                <Grid container justifyContent="end" gap={3}>
                    <RecycleBinTabs />
                    <SearchField
                        color="secondary"
                        variant="outlined"
                        defaultValue={searchTerm}
                        sx={{ maxWidth: '220px' }}
                        onSearchCallBack={(value) => handleSearch(value, true)}
                        placeholder={ENTER_FILE_NAME}
                        automation-id="recyclebin-search"
                        InputProps={{ sx: { maxHeight: '40px' } }}
                        userAction={UserActionWorkspace.WORKSPACE_FILES_SEARCH}
                        userActionCategory={CategoriesWorkspace.WORKSPACE_FILES_PAGE}
                        label={SEARCH_LABEL}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
            </Stack>
            <div className={classNames([classes.table])}>
                <FileList
                    files={data?.files || []}
                    isFetching={isFetching}
                    fields={fields}
                    canRestore={canRestore}
                    handleSort={handleSort}
                    searchTerm={searchTerm}
                />
            </div>
        </Stack>
    );
};
